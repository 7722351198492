<template>
  <div :style="{ backgroundColor: '#F6F6F6' }">
    <CContainer class="d-flex align-items-center min-vh-100  col-md-12">
      <CCol class="ml-1 mr-1 ">
        <CRow class="justify-content-center">
          <div class="text-center">
            <a>
              <img src="/silom-pos.png" class="img-fluid" width="20%" />
            </a>
          </div>
        </CRow>
      </CCol>
    </CContainer>
  </div>
</template>

<script>
export default {
  created() {
    this.getMobileOperatingSystem()
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return window.location.assign(
          'https://play.google.com/store/apps/details?id=com.silomtechnology.android.silompos'
        )
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return window.location.assign(
          'https://apps.apple.com/th/app/silom-pos-point-of-sale/id1230301725'
        )
      }
      return window.location.assign('https://www.silompos.com/')
    },
  },
}
</script>
